const DEV = process.env.NODE_ENV === 'development';

import '@lottiefiles/lottie-player';

import Swiper, { Scrollbar, Mousewheel } from 'swiper';
import 'swiper/css';
import 'swiper/css/scrollbar';

export default {
	init() {
		// JavaScript to be fired on all pages
		console.log('init common');

		// Remove no-js
		document.documentElement.classList.remove('no-js');
		document.documentElement.classList.add('js');

		// Test touch
		if (!('ontouchstart' in document.documentElement)) {
			document.documentElement.classList.add('no-touch');
		} else {
			document.documentElement.classList.add('touch');
		}

		const menu_btn = document.querySelectorAll('.menu_btn')[0];
		const menu_full = document.querySelectorAll('.menu-mobile')[0];
		const menu_item = document.querySelectorAll('.menu-mobile a');

		const menu_desktop_items = document.querySelectorAll('.menu-desktop a');
		menu_desktop_items.forEach((link, index) => {
			link.setAttribute('data-index', index + 1);
		});

		const newsPage = document.getElementById('newsContainer');
		const logo = document.getElementById('logo');

		menu_item.forEach((item, i) => {
			item.addEventListener('click', function (e) {
				menu_full.classList.remove('show_menu');
				document.body.classList.remove('noScroll');
				menu_btn.classList.remove('active');
			});
		});

		if (menu_btn) {
			menu_btn.addEventListener('click', function (e) {
				this.classList.toggle('active');
				menu_full.classList.toggle('show_menu');
				document.body.classList.toggle('noScroll');
			});
		}
		document.addEventListener('keyup', function (e) {
			if (e.keyCode === 27 && $('.menu-full').hasClass('show_menu')) {
				menu_full.classList.remove('show_menu');
				document.body.classList.remove('noScroll');
				menu_btn.classList.remove('active');
			}
		});

		const loader = document.getElementById('loader');

		function hideLoader() {
			loader.classList.add('hidden');
		}

		setTimeout(function () {
			hideLoader();
		}, 500);

		window.addEventListener('load', function () {
			hideLoader();
		});

		let swiper;
		const breakpoint = window.matchMedia('(max-width:1023px)');

		const breakpointChecker = function () {
			if (breakpoint.matches === true) {
				if (swiper !== undefined) swiper.destroy(true, true);
				return;
			} else if (breakpoint.matches === false) {
				return enableSwiper();
			}
		};

		const enableSwiper = function () {
			if (jQuery('.main-swiper').length > 0) {
				swiper = new Swiper('.main-swiper', {
					speed: 1300,
					modules: [Scrollbar, Mousewheel],
					mousewheel: {
						eventsTarget: '#main',
						enabled: true,
						sensitivity: 2.5,
					},
					scrollbar: {
						el: '.swiper-scrollbar',
					},
					passiveListeners: false,
					on: {
						slideChangeTransitionEnd: function (swiper) {
							//Logo
							if (swiper.activeIndex == 0) {
								logo.classList.remove('active');
							} else {
								logo.classList.add('active');
							}

							//Menu
							menu_desktop_items.forEach((link) => {
								link.classList.remove('active');
							});
							if (menu_desktop_items[swiper.activeIndex - 1]) {
								menu_desktop_items[
									swiper.activeIndex - 1
								].classList.add('active');
							}
						},
					},
				});
			}

			if (newsPage) {
				newsPage.addEventListener('mouseenter', function () {
					if (newsPage.scrollHeight > newsPage.clientHeight) {
						swiper.mousewheel.disable();
					}
				});
				newsPage.addEventListener('mouseleave', function () {
					if (!swiper.mousewheel.enabled) {
						swiper.mousewheel.enable();
					}
				});
			}
		};

		breakpoint.addListener(breakpointChecker);
		breakpointChecker();

		document
			.querySelectorAll('#mainHeader a, .js-scroll-to')
			.forEach((link) => {
				link.addEventListener('click', function (e) {
					if (swiper) {
						e.preventDefault();
						let tg = e.target.getAttribute('data-index');
						swiper.slideTo(tg);
					}
				});
			});

		let timeoutAccordion;
		let timeoutAccordionSwiper;

		document.querySelectorAll('.accordion').forEach((link) => {
			const content = link.querySelector('.accordion-content');
			const header = link.querySelector('.accordion-header');
			header.addEventListener('click', () => {
				clearTimeout(timeoutAccordion);
				clearTimeout(timeoutAccordionSwiper);
				if (content.style.maxHeight) {
					link.classList.remove('accordion--open');
					content.style.maxHeight = null;
				} else {
					link.classList.add('accordion--open');
					content.style.maxHeight = content.scrollHeight + 'px';
					timeoutAccordion = setTimeout(function () {
						link.scrollIntoView();
					}, 350);
				}
				timeoutAccordionSwiper = setTimeout(function () {
					if (swiper) {
						if (newsPage.scrollHeight > newsPage.clientHeight) {
							swiper.mousewheel.disable();
						} else if (!swiper.mousewheel.enabled) {
							swiper.mousewheel.enable();
						}
					}
				}, 350);
			});
		});

		document.querySelectorAll('.news-page-menu-item').forEach((link) => {
			const pageID = link.dataset.target;
			link.addEventListener('click', ({ currentTarget }) => {
				document
					.querySelectorAll('.news-page-menu-item, .news-page')
					.forEach((item) => {
						item.classList.remove('active');
					});
				currentTarget.classList.add('active');
				document.getElementById(pageID).classList.add('active');
			});
		});

		jQuery(document).bind(
			'gform_confirmation_loaded',
			function (event, formID) {
				let $singleNews = jQuery('#gf_' + formID).parents(
					'.gravity-news'
				);
				let $contactPage = jQuery('#gf_' + formID).parents('#contact');
				if ($singleNews.length > 0) {
					$singleNews.find('.download-btn').removeClass('hidden');
					$singleNews.find('.download-btn')[0].click();
					$singleNews.find('.gf-news-wrapper').remove();
				}
				if ($contactPage.length > 0) {
					$contactPage.find('form').remove();
				}
			}
		);

		jQuery('.gform_wrapper form').each(function (item) {
			var idForm = jQuery(this).attr('id');
			idForm = idForm.replace('gform_', '');
			window['gf_submitting_' + idForm] = false;
		});

		jQuery('.gform_wrapper form input.gform_button').each(function (item) {
			jQuery(this)[0].onclick = null;
		});
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
		console.log('finalize common');
	},
};
