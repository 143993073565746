export default {
	init() {
		const sections = document.querySelectorAll('section');
		const navItems = document.querySelectorAll('#menu-scr-menu-1 a');
		window.onscroll = () => {
			var current = '';

			sections.forEach((section) => {
				const sectionTop = section.offsetTop;
				if (window.scrollY >= sectionTop - 60) {
					current = section.getAttribute('id');
				}
			});

			navItems.forEach((a) => {
				a.classList.remove('active');
				if (a.getAttribute('href') == '#' + current) {
					a.classList.add('active');
				}
			});
		};
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
		console.log('finalize scr');
	},
};
